import { API_BASE_URL } from "./hostSetting";

const baseUrl = API_BASE_URL;

export const endpoints = {
  hostUrl: baseUrl,
  loginUrl: `${baseUrl}/api/user/login`,
  AdminloginUrl: `${baseUrl}/api/admin/login`,
  forgetPassword: `${baseUrl}/api/password/email`,
  resetPassword: `${baseUrl}/api/reset-password`,

  //Department
  departmentList: `${baseUrl}/api/admin/viewdepts`,
  departmentAdd: `${baseUrl}/api/admin/newdept`,
  departmentUpdate: `${baseUrl}/api/admin/editdepts`,
  departmentDelete: `${baseUrl}/api/admin/delete_depts`,

  //Team
  teamList: `${baseUrl}/api/admin/teams`,
  teamAdd: `${baseUrl}/api/admin/teams`,
  teamUpdate: `${baseUrl}/api/admin/teams`,
  teamDelete: `${baseUrl}/api/admin/teams`,

  //Designation
  designationList: `${baseUrl}/api/admin/designation`,
  designationAdd: `${baseUrl}/api/admin/designation`,
  designationUpdate: `${baseUrl}/api/admin/designation`,
  designationDelete: `${baseUrl}/api/admin/designation`,

  // Settings
  settingsUpdate: `${baseUrl}/api/admin/settings`,
  // User Details
  userList: `${baseUrl}/api/user/user_data`,

  //Request
  requestListAdmin: `${baseUrl}/api/admin/request`,
  requestApproveAdmin: `${baseUrl}/api/admin/request/approve`,
  requestRejectAdmin: `${baseUrl}/api/admin/request/reject`,
  requestDeleteAdmin: `${baseUrl}/api/admin/request/delete`,

  //Timesheet
  timesheetList: `${baseUrl}/api/user/attendancelog`,
  timesheetPunchin: `${baseUrl}/api/user/punchin`,
  timesheetPunchout: `${baseUrl}/api/user/punchout`,
  timesheetBreak: `${baseUrl}/api/user/breaktime`,
  deskshotAdd: `${baseUrl}/api/user/workscreenshort`,


  workdataList:`${baseUrl}/api/user/getuserWorkandBreaktime`,

  //Tasker
  taskerAdd: `${baseUrl}/api/admin/task_management`,
  taskerList: `${baseUrl}/api/admin/task_management`,
  taskerApprove: `${baseUrl}/api/admin/task_management`,
  taskerReject: `${baseUrl}/api/admin/task_management`,
  taskerDelete: `${baseUrl}/api/admin/task_management`,
  taskerComment: `${baseUrl}/api/admin/taskmanagement`,
  taskerReassign: `${baseUrl}/api/admin/reassign-task`,

  //Notification
  notificationList: `${baseUrl}/api/admin/notification`,

  //Department
  announcementList: `${baseUrl}/api/admin/announcement`,
  announcementAdd: `${baseUrl}/api/admin/announcement`,
  announcementUpdate: `${baseUrl}/api/admin/notice_board_details`,
  announcementDelete: `${baseUrl}/api/admin/announcement`,

  //Employee
  employeeList: `${baseUrl}/api/admin/employee`,
  useremployeeList: `${baseUrl}/api/user/get_employees`,
  employeeAdd: `${baseUrl}/api/admin/employee`,
  employeeUpdate: `${baseUrl}/api/admin/employee`,
  employeeDelete: `${baseUrl}/api/admin/employee`,
  resignedemployeeList: `${baseUrl}/api/admin/get_resigned`,
  designationList: `${baseUrl}/api/admin/designation`,
  employeeLogin: `${baseUrl}/api/admin_user/login`,
  employeeGetId:`${baseUrl}/api/admin/get-employeeid`,
  employeeGetbrandId:`${baseUrl}/api/admin/getbrand-employeeid`,

  warnedemployeeList:`${baseUrl}/api/admin/get-warned`,
  suspendedemployeeList:`${baseUrl}/api/admin/get-suspended`,

  //Inventory
  inventoryList: `${baseUrl}/api/admin/inventory`,
  inventoryAdd: `${baseUrl}/api/admin/inventory`,
  inventoryUpdate: `${baseUrl}/api/admin/inventory`,
  inventoryDelete: `${baseUrl}/api/admin/inventory`,

  //Interview
  interviewList: `${baseUrl}/api/admin/interview`,
  interviewAdd: `${baseUrl}/api/admin/interview`,
  interviewUpdate: `${baseUrl}/api/admin/interview_update`,
  interviewDelete: `${baseUrl}/api/admin/interview`,
  createComment : `${baseUrl}/api/admin/addcomment`,

  //Project
  projectList: `${baseUrl}/api/admin/project-room`,
  projectAdd: `${baseUrl}/api/admin/project-room`,
  projectUpdate: `${baseUrl}/api/admin/project-room`,
  projectDelete: `${baseUrl}/api/admin/project-room`,

  //Attendance
  attendanceList: `${baseUrl}/api/admin/attendance`,

  //DailyReport
  dailyreportList: `${baseUrl}/api/admin/daily_datas`,
  dailyactivityList: `${baseUrl}/api/admin/getjd`,
  dailyactivityDelete: `${baseUrl}/api/admin/delete_Activity`,
  dailyactivityAdd: `${baseUrl}/api/admin/add_new_act`,
  jobdesAdd: `${baseUrl}/api/admin/changejd`,

  //WeeklyReport
  weeklyreportList: `${baseUrl}/api/admin/weekly_report`,
  weeklyactivityList: `${baseUrl}/api/admin/getweeklyactivity`,
  weeklyactivityDelete: `${baseUrl}/api/admin/weekly_report`,
  weeklyactivityAdd: `${baseUrl}/api/admin/weekly_report`,

  //MonthlyReport
  monthlyreportList: `${baseUrl}/api/admin/monthly_report`,
  monthlyactivityList: `${baseUrl}/api/admin/getmonthlyactivity`,
  monthlyactivityDelete: `${baseUrl}/api/admin/monthly_report`,
  monthlyactivityAdd: `${baseUrl}/api/admin/monthly_report`,

  //Shiftrecord
  shiftrecordList: `${baseUrl}/api/admin/shiftweek_manager/getweeks`,
  shiftrecordView: `${baseUrl}/api/admin/shiftweek_manager/loadPreviousShift`,

  //Hashbook
  hashbookList: `${baseUrl}/api/discussion`,
  hashbookAdd: `${baseUrl}/api/discussion`,
  hashbookUpdate: `${baseUrl}/api/discussion`,
  hashbookDelete: `${baseUrl}/api/discussion`,
  hashbookAuthors: `${baseUrl}/api/get_authors`,
  hashbookCreateComment: `${baseUrl}/api/create_comment`,
  hashbookCommentDetails: `${baseUrl}/api/discussion_details`,
  hashbookSubTopic : `${baseUrl}/api/create_subtopic`,

  //EmployeeSkill
  employeeskillList: `${baseUrl}/api/admin/getEmployeeSkillSet`,
  employeeskillAdd: `${baseUrl}/api/admin/addNewSkill`,
  employeeskillDelete: `${baseUrl}/api/admin/removeSkill`,
  employeeskillReview: `${baseUrl}/api/admin/changeSkillStatus`,
  employeeProfileUpload: `${baseUrl}/api/admin/manage_upload`,

  //Leave Request
  requestList: `${baseUrl}/api/user/request_leave`,
  requestSend: `${baseUrl}/api/user/request_leave`,
  requestListType: `${baseUrl}/api/user/request_type`,
  requestMethod: `${baseUrl}/api/user/get_leave_method`,

  //My scores User
  scoreList: `${baseUrl}/api/user/score`,
  performanceEvaluation: `${baseUrl}/api/user/get_evaluation_details`,
  performanceEvaluationHistory: `${baseUrl}/api/user/get_evaluation_history`,

  //MyTask
  taskList: `${baseUrl}/api/user/get_task`,
  postComment: `${baseUrl}/api/user/post_comment`,
  taskReassign: `${baseUrl}/api/user/reassign-task`,

  
  //Shift Schedule
  teamMembers: `${baseUrl}/api/user/getTeamMembers`,

  //Worksheet
  ticketList: `${baseUrl}/api/user/ticket_count`,
  skillView: `${baseUrl}/api/user/skill_list`,
  skillUpdate: `${baseUrl}/api/user/skill_status_update`,
  dailyReport: `${baseUrl}/api/user/work_data`,
  dailyReportAdd: `${baseUrl}/api/user/work_data`,
  reportWeekly: `${baseUrl}/api/user/weekly_data`,
  reportAddWeekly: `${baseUrl}/api/user/save_weekly_data`,
  monthlyReport: `${baseUrl}/api/user/monthly_data`,
  monthlyReportAdd: `${baseUrl}/api/user/save_montly_data`,
  ticketDetails: `${baseUrl}/api/user/get_saved_ticket_details`,
  ticketUpdate: `${baseUrl}/api/user/update_ticket_response`,
  ticketSave: `${baseUrl}/api/user/ticket_data_save`,
  viewReport : `${baseUrl}/api/user/work_report`,

  //Shift
  teamMembers :`${baseUrl}/api/user/getTeamMembers`,
  createShift:`${baseUrl}/api/user/createShift`,
  getWeeks:`${baseUrl}/api/user/getWeeks`,
  getPreviousShift:`${baseUrl}/api/user/loadPreviousShift`,
  editShift:`${baseUrl}/api/user/editShifts`,
  swapShift:`${baseUrl}/api/user/swapShift`,
  swapDelete:`${baseUrl}/api/user/deleteSwap`,
  swapComment:`${baseUrl}/api/user/updateComment`,
  loadShifts:`${baseUrl}/api/user/loadShifts`,


  //Performance Admin
  getEmployeePerformance : `${baseUrl}/api/admin/performance`,
  resetOvertime : `${baseUrl}/api/admin/overtime_reset`,
  updateMandatory : `${baseUrl}/api/admin/update_mandatory`,
  manageWarning : `${baseUrl}/api/admin/manage_warning`,
  performanceUpdate : `${baseUrl}/api/admin/performance_update`,

  //reportmonth

  reportmonthlist: `${baseUrl}/api/admin/report_month`,
  reportlist: `${baseUrl}/api/admin/report_list`,

  //teamreport
  teamreportmonthlist: `${baseUrl}/api/user/report-month`,
  teamlist: `${baseUrl}/api/user/team-list`,
  teamreportlist: `${baseUrl}/api/user/report-list`,

  //hike
  getHike: `${baseUrl}/api/admin/hike-list`,

  //user profile
  userProfileUpload: `${baseUrl}/api/user/manage_upload`,
//daily report of month
  reportmonthData: `${baseUrl}/api/admin/report-full-month`,


  //Client
  clientList: `${baseUrl}/api/admin/clients`,
  clientAdd: `${baseUrl}/api/admin/clients`,
  clientUpdate: `${baseUrl}/api/admin/clients`,
  clientDelete: `${baseUrl}/api/admin/clients`,

  //Client
  clientRevenueList: `${baseUrl}/api/admin/client-lists`,
  clientRevenueAdd: `${baseUrl}/api/admin/client-lists`,
  clientRevenueUpdate: `${baseUrl}/api/admin/client-lists`,
  clientRevenueDelete: `${baseUrl}/api/admin/clients-lists`,


  ticketClient: `${baseUrl}/api/user/client-list`,
  workGraph: `${baseUrl}/api/user/work-graph`,

  //countries
  getCountry: `${baseUrl}/api/admin/get-countries`,

 
  //adminhash

  adminHashbook:`${baseUrl}/api/admin/add-hashbook/get`,
  adminhashDelete:`${baseUrl}/api/admin/add-hashbook`,
  adminhashAdd: `${baseUrl}/api/admin/add-hashbook`,
  adminhashUpdate: `${baseUrl}/api/admin/add-hashbook`,
  adminhashShow: `${baseUrl}/api/admin/add-hashbook/showdata`,
  adminhashtopicadd: `${baseUrl}/api/admin/add-hashbook-topic`,
  adminhashtopicshow: `${baseUrl}/api/admin/get-hashbook-topic`,
  adminhashtopicdelete: `${baseUrl}/api/admin/delete-hashbook-topic`,
  adminhashtopicUpdate: `${baseUrl}/api/admin/update-hashbook-topic`,
  adminlockHashbook: `${baseUrl}/api/admin/lock-hashbook`,



  //userhash
  hashbookuserList:`${baseUrl}/api/user/hashbook`,

  UserhashShow: `${baseUrl}/api/user/hashbook/show`,
  TopichashShow: `${baseUrl}/api/user/hashbook/show/topic`,
  UserHashDepTeam: `${baseUrl}/api/user/get-hashbook-depteam`,
  UserHashAddTopic: `${baseUrl}/api/user/add-hashbook-topic`,
  UserhashUpdate: `${baseUrl}/api/user/update-hashbook-topic`,
  UserHashAddsubTopic: `${baseUrl}/api/user/subtopic-hashbook`,
  UsersubhashUpdate: `${baseUrl}/api/user/update-hashbook-subtopic`,
  
  ///leaves user

  getUserTeam:`${baseUrl}/api/user/get/team/leads`,


// leave approval

 //Request
 requestListUser: `${baseUrl}/api/user/request/data`,
 requestApproveUser: `${baseUrl}/api/user/request/approve`,
 requestRejectUser: `${baseUrl}/api/user/request/reject`,
 requestDeleteUser: `${baseUrl}/api/user/request/delete`,

 //admin Role


   RoleList: `${baseUrl}/api/admin/role`,
    RoleAdd: `${baseUrl}/api/admin/role`,
    RoleUpdate: `${baseUrl}/api/admin/role`,
    RoleDelete: `${baseUrl}/api/admin/role`,

    //add salary payrol
    employeeSalaryList: `${baseUrl}/api/admin/get-employee-list`,
    PayrollList: `${baseUrl}/api/admin/get-employee-salary-fetch`,
    PayrollAdd: `${baseUrl}/api/admin/add-payroll`,
    PayrollUpdate: `${baseUrl}/api/admin/update-payroll`,
    PayrollDelete: `${baseUrl}/api/admin/delete-payroll`,

    //salary details
    PayrollDetailsListData: `${baseUrl}/api/admin/get-payroll-details`,
    PayrollDetailsDataAdd: `${baseUrl}/api/admin/add-payroll-details`,
    PayrollDetailsDelete: `${baseUrl}/api/admin/delete-payroll-details`,
    PayrollDetailsUpdate: `${baseUrl}/api/admin/update-payroll-details`,

    SalaryDetailsFetch: `${baseUrl}/api/admin/get-latest-salary-details`,
//Admin list
    AdminList: `${baseUrl}/api/admin/admin/fetch`,
    AdminAdd: `${baseUrl}/api/admin/admin/list`,
    AdminUpdate: `${baseUrl}/api/admin/admin/list`,
    AdminDelete: `${baseUrl}/api/admin/admin/list`,
    linkedin_update:`${baseUrl}/api/user/linkedin_update`,
    break_add:`${baseUrl}/api/user/break_add`,
    task_notification_update:`${baseUrl}/api/user/task_notify`,
    //update notification
    
    update_notify:`${baseUrl}/api/user/update-notify`,

    // onboarding

    OnboardList: `${baseUrl}/api/admin/onboarding`,
    OnboardAdd: `${baseUrl}/api/admin/onboarding`,
    OnboardUpdate: `${baseUrl}/api/admin/onboarding`,
    OnboardDelete: `${baseUrl}/api/admin/onboarding`,
    OnboardView: `${baseUrl}/api/admin/onboarding`,
    OnboardInvite: `${baseUrl}/api/admin/invite`,

};
